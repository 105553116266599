import React from "react";
import "./Home.css";
import Banner from "../../components/Banner";
import Skills from "../../components/Skills";
import Me from "../../components/Me";
import { FaAnglesDown } from "react-icons/fa6";
import WebDevelopment from "../../components/WebDevelopment";
import ServerManagement from "../../components/ServerManagement";
import NavBar from "../../components/NavBar";
import Reveal from "../../components/Reveal";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div id="Home">
      <NavBar />
      <Banner />
      <div className="pointDown left">
        <FaAnglesDown />
      </div>
      <div className="pointDown">
        <FaAnglesDown />
      </div>
      <div className="pointDown right">
        <FaAnglesDown />
      </div>
      <div className="sections">
        <Reveal>
          <Me />
        </Reveal>
        <Reveal>
          <Skills />
        </Reveal>
        <Reveal>
          <WebDevelopment />
        </Reveal>
        <Reveal>
          <ServerManagement />
        </Reveal>

        <div className="flex justify-center align-center flex-col" style={{ margin: "3rem auto"}}>
          <h2>Want a website of your own?</h2>
          <NavLink to="/enquiry" className="contact">
            Contact Me
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Home;
