import React from "react";

function Me() {
  return (
    <div className="Me">
      <h2>Who am I?</h2>
      <div className="flex justify-center align-center half-half">
        <span>
          <p>
            If you're wondering, it's pronounced Luke "Dee"-Ath. My family & I
            think it's of belgian origin - perhaps!
          </p>
          <p>
            I'm training in my personal time to be a build websites from
            scratch. This means I can <strong>personalise</strong> a site without being
            restricted by templates/website builders.
          </p>
          <p>Age: 23</p>
        </span>
        <img src="/Images/Me.jpg" alt="Portrait of Luke De-Ath." />
      </div>
    </div>
  );
}

export default Me;
