import React from "react";

function WebDevelopment() {
  return (
    <div className="WebDevelopment">
      <h2>Web Development</h2>
      <div>
        <div>
          <h3>SW Gardening Services</h3>
          <div className="site-preview">
            <a
              href="https://swgardeningservices.netlify.app/"
              style={{ width: "100%" }}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/Images/sw-gardening-services.png"
                alt="Screenshot of SW Gardening Services' web site, developed by Luke De-Ath"
              />
            </a>
          </div>
        </div>
        <div>
          <h3>May Maid Makes</h3>
          <div className="site-preview">
            <a
              href="https://may-maid-makes-dev.netlify.app/"
              style={{ width: "100%" }}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/Images/may-maid-makes.png"
                alt="Screenshot of May Maid Makes' web site, developed by Luke De-Ath"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDevelopment;
