import React from "react";
import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <ul className="NavBar">
      <li>
        <NavLink to="/">Home</NavLink>
      </li>

      <li className="contact">
        <NavLink to="/enquiry">
          Contact Me
        </NavLink>
      </li>
    </ul>
  );
}

export default NavBar;
